body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: sans-serif!important;
  margin-top: 80px!important;
  padding: 1rem;
}

.header_style {
  background-color: rgb(254, 214, 39)!important;
  -webkit-filter: drop-shadow(.1rem .1rem .1rem rgba(0, 0, 0, 0.5));
          filter: drop-shadow(.1rem .1rem .1rem rgba(0, 0, 0, 0.5));
  padding: 0.5rem!important;
}

.header_title {
  font-size: 2rem!important;

  font-weight: bold;
}

#accordion_menu {
  margin-bottom:1rem;
}

#accordion_menu table {
  table-layout: auto;
  width: auto;
  vertical-align: middle;
  white-space: nowrap;
}

.autotable {
  width: auto!important;
}
